.coupon-box h1 {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    text-transform: none;
    margin: 30px 0 20px;
    padding: 0;
}

.coupon-box .box {
    width: 100%;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 12px;
    border-radius: 15px;
    overflow: hidden;
}