.innovations-feeds-video-player {
  background-color: #000000;
}

.innovation-actions span {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-innovation {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
}

.btn-cancel {
  background-color: #ed2024;
  color: #ffffff;
}

.btn-cancel-confirm {
  background-color: #274524;
  color: #ffffff;
}

.btn-cancel-cancel {
  background-color: #ed2024;
  color: #ffffff;
}

.btn-cancel-confirm:hover {
  background-color: #30742a;
  color: #ffffff;
}

.btn-cancel-cancel:hover {
  background-color: #c90003;
  color: #ffffff;
}

.btn-cancel:hover {
  background-color: #c90003;
  color: #ffffff;
}

.btn-cancel:disabled {
  background-color: #941315;
  color: #ffffff;
  cursor: not-allowed;
}

.innovation-badge {
  border-radius: 0;
  padding: 0.05rem 0.35rem;
  font-size: 12px;
  color: black;
  font-weight: 400;
}

.innovation-submitted {
  background-color: #f1eea1;
}

.innovation-accepted {
  background-color: #a8f1a1;
}

.innovation-rejected {
  background-color: #f1a1a1;
}
