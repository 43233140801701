.congrats {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 500px;
  /* height: 200px; */
  /* background-color: white;
    border: 1px solid black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */

  z-index: 9999998 !important;
}

.congrats-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 75%);
  z-index: 9999997 !important;
}


.congrts_bg {
  width: 500px;
  display: block;
  background-position: center;
  justify-content: center;
  align-items: center;
}
.congrts_bg img {
  width: 100%;
  /* object-fit: cover; */
}

.congrts_cont {
  width: 100%;
  display: inline-block;
  color: #ffd000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}