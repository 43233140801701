.redeemedCoupons .swiper-slide {
    font-size: 18px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.redeemedCoupons .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.redeemedCoupons .swiper-button-prev {
    left: 35px !important;
}

.redeemedCoupons .swiper-button-next {
    right: 35px !important;
}

.redeemedCoupons .swiper-button-next,
.redeemedCoupons .swiper-button-prev {
    position: absolute;
    top: 70%;
    color: #000;
}

.play-btn {
    /* background: rgb(211, 60, 38);
    background: linear-gradient(0deg, rgba(211, 60, 38, 1) 0%, rgba(255, 125, 106, 1) 100%); */
    font-size: 18px;
    font-weight: 600;
    float: left;
    /* padding: 8px 20px; */
    text-transform: uppercase;
    border-radius: 50px;
    height: auto;
    color: #fff;
    border: none;
}

/* .card {
    width: 20rem;
} */