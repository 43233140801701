.read-comics-swiper {
    height: 100%;
    width: 100%;
}

.read-comics-swiper .swiper-slide {
    background-position: center;
    background-size: cover;
}

.read-comics-swiper .swiper-slide img {
    display: block;
    width: 100%;
}

.read-comics-goback {
    background: rgb(171, 171, 171);
    background: linear-gradient(0deg, rgb(196, 196, 196) 0%, rgb(255, 255, 255) 100%);
    font-size: 15px;
    font-weight: 400;
    padding: 5px 15px;
    text-transform: uppercase;
    border-radius: 50px;
    height: auto;
    color: #000000;
    border: none;
    float: right;
}

.modal-90w .modal-content {
    background-color: #fff;
}