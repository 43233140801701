/* updated by Tina on 29 Oct24 - added styles for button follow and unfollow  */
  .l-l {
    text-align: center; /* Center the button horizontally */
  }
  
  .profile-image {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
  }
  
  .follow-btn-container {
    margin-top: 10px; /* Space between the image and button */
  }
  
  .follow-btn {
    background-color: #bbeef7; /* Change to your desired color */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight:bold;
    border-radius: 15px; /* Optional: rounded corners */
    transition: background-color 0.3s; /* Optional: transition effect */
    visibility: false;
   
   }

   .follow-btn.button-text {
    visibility: visible; /* Ensure the text is always visible even when loading */
    opacity: 1;
    display: inline-block;     /* Ensure it's inline with the loader */
    align-items: right; /* Vertically center the text */
  }
  
  .follow-btn:hover {
    background-color: rgb(183, 221, 183); /* Change color on hover */
  }
  .unfollow-btn-container {
    margin-top: 10px; /* Space between the image and button */
  }
  
  /* .unfollow-btn {
    background-color: #bbeef7;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight:bold;
    border-radius: 15px;
    transition: background-color 0.3s;
    visibility: false;
    
   }
  
  .unfollow-btn:hover {
    background-color: rgb(183, 221, 183);
  }
  .unfollow-btn.button-text {
    visibility: visible;
    opacity: 1;
    display: inline-block; 
    align-items: right;
  } */
   
  /* .unfollow-btn:disabled .button-content {
    visibility: visible; 
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
  }

  .unfollow-btn:disabled .loader {
    visibility: visible;
  }

  .unfollow-btn:enabled .loader {
    visibility: hidden;
  } */
  /* Make sure button text remains visible while the loader is active */
  .follow-btn:disabled .button-content {
    visibility: visible; /* Hide the text while the loader is visible */
    opacity: 1; /* Optional: slightly fade the text when loading */
    display: flex;
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Center horizontally */
    gap: 90px; /* Space between the loader and the text */
  }
  
  .follow-btn:disabled .loader {
    visibility: visible; /* Loader is always visible */
  }
  
  .follow-btn:enabled .loader {
    visibility: hidden; /* Hide the loader when not loading */
  }
  
  /* Style for button content */
  .button-content {
    display: flex;
    gap: 90px; /* Space between the loader and the text */
    align-items: right; /* Vertically center the content */
  }
   /* Loader Styling */
  .loader {
    width: 24px;
    height: 24px;
    border: 4px solid transparent;
    border-top: 4px solid rgb(22, 61, 112); /* White spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 90px;         /* Space between the loader and text */
    position: absolute; /* Position loader over the button */
    /* left: 10px;  */
    
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  /* Button text styling */
  .button-text {
    visibility: visible; /* Ensure the text is always visible even when loading */
    opacity: 1;
    display: inline-block;     /* Ensure it's inline with the loader */
    align-items: right; /* Vertically center the text */
  }
    
  
  
  