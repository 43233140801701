/* .past-btn {
    background: rgb(242, 158, 0);
    background: linear-gradient(0deg, rgba(242, 158, 0, 1) 0%, rgba(254, 182, 2, 1) 100%);
    font-size: 15px;
    font-weight: 400;
    padding: 5px 15px;
    text-transform: uppercase;
    border-radius: 50px;
    height: auto;
    color: #fff;
    border: none;
    position: absolute;
    right: 150px;
}

.live-btn {
    background: rgb(43, 191, 121);
    background: linear-gradient(0deg, rgba(43, 191, 121, 1) 0%, rgba(142, 230, 188, 1) 100%);
    font-size: 15px;
    font-weight: 400;
    padding: 5px 15px;
    text-transform: uppercase;
    border-radius: 50px;
    height: auto;
    color: #fff;
    border: none;
    position: absolute;
    right: 150px;
} */

.contest-loader {
  position: relative;
  width: 100%;
}

.contest-loader span {
  margin: auto;
}

.title > span {
  display: inline !important;
  margin-left: 15px;
}
