.btn-container {
  padding: 15px;

  button {
    height: 200px;
  }
}

.form-check {
  cursor: pointer !important;
}
