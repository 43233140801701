.play-learn li {
    width: 125px;
}

.play-learn p {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #d9534f;
    padding-top: 10px;
    font-weight: 500;
    text-transform: capitalize;

}