div .button {
    cursor: pointer;
}

.go-back {
    background: rgb(171, 171, 171);
    background: linear-gradient(0deg, rgb(196, 196, 196) 0%, rgb(255, 255, 255) 100%);
    /* font-size: 15px; */
    font-weight: 400;
    padding: 5px 15px;
    text-transform: uppercase;
    border-radius: 15px;
    height: auto;
    color: #000000;
    border: none;
    margin-right: 15px;
}

ul {
    width: 100% !important;
}

small {
    font-size: 0.700em;
}