/* button.skip-video-button {
    position: absolute;
    bottom: 60px;
    right: 50px;
} */
button.skip-video-button {
    bottom: 60px;
    right: 50px;
    position: absolute;
background: rgb(211, 60, 38);
background: linear-gradient(0deg, rgba(211, 60, 38, 1) 0%, rgba(255, 125, 106, 1) 100%);
font-size: 16px;
font-weight: 600;
padding: 8px 20px;
text-transform: uppercase;
border-radius: 50px;
height: auto;
color: #fff;
border: none;
cursor: pointer;
}
button.skip-video-button:hover {
    background: rgb(255, 125, 106);
    background: linear-gradient(0deg, rgba(255, 125, 106, 1) 0%, rgba(211, 60, 38, 1) 100%);
} 