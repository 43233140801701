/* .ProfileSlider {
} */

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background-color: #fff;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
}

.challenge-box h1 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  text-transform: none;
  margin: 0 0 20px;
  padding: 0;
  border-radius: 10px;
}

.challenge-shorts .shorts > .react-thumbnail-generator {
  width: 139px;
  height: 248px;
  overflow: hidden;
  border-radius: 10px;
}

.challenge-shorts .shorts {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
}

.challenge-shorts .shorts .overlay {
  background-color: #000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
}

.challenge-shorts .shorts .play-btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.challenge-shorts .shorts .play-btn img {
  width: 30px;
}

.challenge-shorts .rating {
  margin: 10px 0 5px;
  float: left;
  width: 100%;
}

.challenge-shorts .rating i {
  color: #fed144;
  font-size: 18px;
  margin: 0 -1px;
}

.challenge-shorts .rating .count {
  font-size: 14px;
  color: var(--m-black);
  margin-left: 5px;
}

.challenge-shorts .love,
.challenge-shorts .like {
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 5px;
  border: #e0e0e0 solid 1px;
  font-size: 13px;
  color: var(--m-black);
}

.challenge-shorts .badge {
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 5px;
  border: #e0e0e0 solid 1px;
  font-size: 13px;
  font-weight: 400;
  color: var(--m-black);
  margin-top: 10px;
}

.pending {
  border-radius: 3px;
  padding: 3px 5px;
  border: #e0e0e0 solid 1px;
  font-size: 13px;
  font-weight: 400;
  color: var(--m-black);
  margin-top: 10px;
  display: inline-block;
}

.pending i {
  font-size: 18px;
  margin: 0 -1px;
}

.play-btn {
  cursor: pointer;
}

.challenges-title > span {
  display: inline !important;
  margin-left: 15px;
}

.elements > span > img {
  margin-top: -2px;
  margin-right: 2px;
  width: 15px;
}

.feeds-container {
  height: 420px;
  overflow-y: auto;
}

.followers-list {
  height: 420px;
  overflow-y: auto;
}

/* updated by Tina on 11 Nov24 - added styles for tab */

.tabs button.active {
  background-color: #a3d0d8;
  color: white;
}
.tabs button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px; /* Curved or pill-shaped button */
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #b3adad;
  margin-right: 5px;
}

.follower-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.follower-image {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Make the image round */
  margin-right: 10px; /* No space between image and name */
}

.follower-name {
  flex-grow: 1; /* Allow the name to take up remaining space */
}

.unfollow-btn {
  margin-left: 10px; /* Adjust as needed */
}

.unfollow-confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.unfollow-btn-container {
  margin-top: 10px; /* Space between the image and button */
  justify-content: space-between;
}

/* .unfollow-btn {
  width: 100px;
  height: 30px;
  background-color: #a3d0d8; 
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.unfollow-btn:hover {
  background-color: rgb(183, 221, 183);
} */

.btn-pill {
  border-radius: 50px; /* Adjust as needed for pill shape */
}